<script>
  import RotateMobile from "./RotateMobile.svelte";
  
  export let fadedBackground = true;

  let promise;

  function fullscreen() {
    if (window.innerWidth < 960) {
      let fullscreenRequest;

      if (document.body.requestFullscreen) fullscreenRequest = document.body.requestFullscreen();
      else if (document.body.webkitRequestFullscreen) fullscreenRequest = document.body.webkitRequestFullscreen();
      else if (document.body.mozFullscreen) fullscreenRequest = document.body.mozFullscreen();

      if (fullscreenRequest && typeof fullscreenRequest.then == "function" && typeof fullscreenRequest.catch == "function") promise = fullscreenRequest;
      else promise = Promise.resolve();
    }
    else promise = Promise.resolve();

    promise.then(() => {
      return $$props.then();
    }).then(() => {
      promise = null;
    }).catch((err) => {
      console.error(err);
      return $$props.then();
    })
  }

  // let loadingImg;
  // let loading = true;

  // function loadedImg() {
  //   loading = false;
  // }

  // $: {
  //   if (loadingImg) {
  //     if (loadingImg.complete) loadedImg();
  //     else loadingImg.addEventListener("load",loadedImg);
  //   }
  // }

</script>

<main class:faded={fadedBackground}>
  <!-- <img src="public/splash-loading.png" class="splash" class:loading={!loading} class:blur={true} draggable="false" alt="loading splash">
  <img src="public/splash-high-res.jpg" class="splash high" class:loading={loading} class:blur={loading} draggable="false" bind:this={loadingImg} alt="splash"> -->
  <div class="splash">
    <img id="brand" src="public/maniking.svg" draggable="false" alt="maniking logo">
    <img id="logo" src="public/mainbanner.png" alt="king of the mani" draggable="false">
  </div>
  {#if promise}
    <p>CARGANDO...</p>
  {:else}
    <button class="only-landscape" on:click={fullscreen}></button>
  {/if}
  <p class="only-portrait">Rotá tu teléfono</p>
  <div class="only-portrait">
    <RotateMobile />
  </div>


</main>

<style>
  main {
    position: fixed;
    z-index: 3;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  main.faded {
    background-color: #DDDDDD55;
  }
  .splash {
    width: 60vh;
    width: min(60vh, 100vw);
    max-width: 400px;
    position: relative;
    z-index: -1;
    margin-bottom: -3%;
  }
  .splash, .splash * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  .splash #logo {
    width: 100%;
    position: relative;
    z-index: 1;

  }
  #brand {
    position: absolute;
    width: 30%;
    left: 35%;
    transform: translateY(-32%);
    z-index: 2;
  }
  button {
    background-color: transparent;
    padding: 16px;
    border: none;
    font-size: 32px;
    background-image: url("public/play-button.png");    
    background-size: 100%;
    background-repeat: no-repeat;
    width: 140px;
    height: 70px;
    cursor: pointer;
  }
  button:hover {
    opacity: 0.8;
  }
  .only-portrait {
    display: none;
  }

  .only-landscape {
    display: flex;
  }

  p {
    font-family: '8bit', sans-serif;
    letter-spacing: -1.2;
    font-size: 20px;
    text-shadow: 0.1em 0.1em white;
    padding-bottom: 12px;
    margin: 0px;
    text-align: center;
  }

  @media screen and (orientation:portrait) {
    .only-portrait {
      display: flex;
    }
    .only-landscape {
      display: none;
    }
  }

</style>