<script>
  import EnterFullscreen from "./EnterFullscreen.svelte";

</script>

<EnterFullscreen then={$$props.then} fadedBackground={false}/>
<main>
  <div class="clouds"></div>
  <div class="background">
    <img id="left" src="public/splash/splash-left.png" draggable="false" alt="borracho">
    <img id="right" src="public/splash/splash-right.png" draggable="false" alt="cuartetero">
    <img id="center" src="public/splash/splash-center.png" draggable="false" alt="fábricas de maniking">
    <img id="bottom-left" src="public/splash/splash-bottom-left.png" draggable="false" alt="colina">
    <img id="bottom-right" src="public/splash/splash-bottom-right.png" draggable="false" alt="colina">
  </div>
  <img class="mani" id="first" src="public/splash/mani-back.png" draggable="false" alt="mani">
  <img class="mani" id="second" src="public/splash/mani-back.png" draggable="false" alt="mani">
  <img class="mani" id="third" src="public/splash/mani-front.png" draggable="false" alt="mani">
  <img class="mani" id="forth" src="public/splash/mani-front.png" draggable="false" alt="mani">

</main>


<style>
  main {
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(#69F8FF, #FFCFBA, #FFFF78);
  }

.clouds {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-image: url("public/nubes-left.png"), url("public/nubes-right.png");
  background-position: top left, top right;
  background-size: 50% auto, 50% auto;
  background-repeat: repeat-y, repeat-y;
}

.background {
  width: 100%;
  height: 50vw;
  position: absolute;
  bottom: 0px;
}


.background * {
  position: absolute;
}

.background #bottom-left {
  width: 40%;
  left: 0px;
  bottom: 0px;
}

.background #bottom-right {
  width: 40%;
  right: 0px;
  bottom: 0px;
}

.background #center {
  width: 60%;
  left: 23%;
  bottom: 0px;
}


.background #left {
  width: 35%;
  left: 12%;
  bottom: 0px;
}

.background #right {
  width: 30%;
  right: 7%;
  bottom: 11%;
}

.mani {
  position: absolute;
  width: 25vh;
  z-index: 1;
}

.mani#first {
  top: 10%;
  left: 20%;
}

.mani#second {
  top: 28%;
  right: 16%;
  transform: rotate(278deg);
}


.mani#third {
  top: 28%;
  left: 23%;
  transform: scale(1.7) translateX(-100%) rotate(153deg);
}

.mani#forth {
  top: 0px;
  right: 24%;
  transform: scale(1.5) translateX(100%) rotate(167deg);
}


</style>