import * as PIXI from "pixi.js";

const app = new PIXI.Application({
  antialias: false,
  resizeTo: window,
  backgroundColor: 0xc7f2ff,
});

//app.baseUrl = (window.location.pathname||"").replace(/(\/)?$/,"/");
app.baseUrl = window.location.pathname.length > 1 ? "/play/" : "/";

app.renderer.backgroundColor = 0x000000;

app.ticker.maxFPS = 30;

app.stage.freeze = false;


// will support high-density displays when rendering
PIXI.settings.RESOLUTION = window.devicePixelRatio;

PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

let repositionEvents = [];

app.stage.__defineSetter__("onReposition", (cb) => {
  repositionEvents.push(cb);
})

app.stage.clearReposition = (cb) => {
  repositionEvents = repositionEvents.filter(fn => fn !== cb);
}

app.stage.__defineGetter__("deepchildren", () => {
  return app.stage.children.reduce((arr, a) => [...arr, ...(a instanceof PIXI.Container ? a.children : [a])], []);
})

app.stage.dispatchReposition = (x, y) => {
  if (app.stage.freeze) return;
  app.stage.position.x = x;
  if (y != undefined) app.stage.position.y = y;
  repositionEvents.forEach(cb => cb(app.stage.position.x, app.stage.position.y));
}

app.stage.playerCenter = 0.33;
app.stage.centerPlayer = () => {
  if (!app.player) return;
  app.stage.dispatchReposition(-app.player.position.x*app.stage.scale.x + window.innerWidth*app.stage.playerCenter);
}

app.setTimeout = (fn, ticks) => {
  let t = 0; 

  const listener = () => {
    t++;
    if (t >= ticks) {
      app.ticker.remove(listener)
      fn();
    }
  };

  app.ticker.add(listener)
}


let paused = false;
let lastFilters = "";
let blurCallback = () => {};

const onBlur = () => {
  if (paused) return;
  app.ticker.stop();
  paused = true;

  lastFilters = app.view && (app.view.style.filter || "");

  app.view.style.filter = lastFilters + " blur(6px)";

  blurCallback(() => {
    app.ticker.start();
    app.view.style.filter = lastFilters;
    paused = false;
  });
}

const onOrientationChange = () => (window.innerHeight < window.innerWidth) && onBlur()

const onFullscreenChange = () => (!document.fullscreenElement) && onBlur();

const onContextMenu = (ev) => ev.preventDefault();


export function addOnBlur(cb) {
  blurCallback = cb;
  window.addEventListener("blur", onBlur);
  window.addEventListener("orientationchange", onOrientationChange);
  document.addEventListener("fullscreenchange", onFullscreenChange);
  window.addEventListener("contextmenu", onContextMenu);
};

export function removeOnBlur(cb) {
  blurCallback = () => {};
  window.removeEventListener("blur", onBlur);
  window.removeEventListener("orientationchange", onOrientationChange);
  document.removeEventListener("fullscreenchange", onFullscreenChange);
  window.removeEventListener("contextmenu", onContextMenu);
};

export default app;
