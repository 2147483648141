
import 'core-js/stable';
import 'regenerator-runtime/runtime';
//import "./utils/errorManager";
import app from "./core";
//import init from "./game";
//import gameInit from "./game";
import * as PIXI from "pixi.js";

const gameModule = import(/* webpackChunkName: "game" */ "./game")
//const gameModule = Promise.resolve({default: gameInit})

  // .then((data) => {
  //   return new Promise((res) => {
  //     setTimeout(() => {
  //       res(data)
  //     }, 2000)
  //   })
  // })

import Splash from "./ui/Splash";

function initGame() {

  return gameModule
    .then(({default: init}) => {
      return init();
    })
}

const component = new Splash({
  target: document.body,
  props: {
    then: () => {
      return initGame()
        .then(() => {
          component.$destroy();
        });
    }
  }
});



// init();
// component.$destroy();